<template>
  <div
    class="bg-white cursor-pointer hover:bg-WALIGHTBLUE-50 max-w-screen-2xl mb-1 mt-0 mx-auto p-4 rounded-lg shadow-2xl shadow-gray-100 lg:px-4"
  >
    <div class="flex items-center justify-between">
      <div class="flex flex-row">
        <div class="flex pr-1 w-44 md:w-80">
          <p
            @click="previewDocument"
            class="text-WADARKBLUE-500 text-sm font-thin truncate hover:underline"
          >
            {{ document.name }}
          </p>
        </div>
        <div class="hidden px-1 w-16 sm:flex text-center">
          <a
            :href="getUrl"
            target="_blank"
            download
            class="text-WADARKBLUE-500 text-sm font-thin"
            @click.prevent="downloadItem"
          >
            <img src="/download-icon.svg" alt="download" />
          </a>
        </div>
        <div class="hidden px-1 w-40 sm:flex">
          <p class="text-WADARKBLUE-500 text-sm font-thin truncate">
            {{ document?.doctype?.name }}
          </p>
        </div>
        <div class="hidden px-1 w-28 sm:flex">
          <p class="text-WADARKBLUE-500 text-sm font-thin">
            {{ formatDate(document.createdAt) }}
          </p>
        </div>
        <div class="hidden px-1 w-36 lg:flex">
          <p class="text-WADARKBLUE-500 text-sm font-thin truncate">
            {{ document?.group?.name }}
          </p>
        </div>
        <div class="hidden px-1 w-44 lg:flex">
          <p class="text-WADARKBLUE-500 text-sm font-thin truncate">
            {{ document?.subgroup?.name }}
          </p>
        </div>
        <div class="hidden px-1 w-36 lg:flex">
          <p class="text-WADARKBLUE-500 text-sm font-thin truncate">
            {{ document?.sub_subgroup?.name || " " }}
          </p>
        </div>
      </div>
      <div class="flex">
        <div class="flex relative justify-end w-20">
          <button
            @click="openStatusModal(document)"
            class="flex font-thin text-sm items-center"
            :class="{
              'text-WAGREEN-500': document.status === 'Active',
              'text-WAORANGE-500': document.status === 'Disabled',
            }"
            :disabled="!isAdmin"
          >
            {{ document.status }}
            <img
              v-if="isAdmin"
              class="w-2 ml-1"
              src="/chevron-down-dark.svg"
              alt="chevron"
            />
          </button>
          <status-modal
            v-if="showStatus.status && showStatus.id === document._id"
            v-model="status"
            :data="statusData"
            @close="closeStatusModal"
          ></status-modal>
        </div>
        <div class="relative flex justify-end w-20" v-if="isAdminOrEdit">
          <option-dropdown-new
            @btnClick="openOptionDropDown(document._id)"
            @onClose="closeOptionDropdown"
            :show="
              showOptionDropdown.status &&
              showOptionDropdown.id === document._id
            "
          >
            <button
              @click.prevent="$emit('edit')"
              class="px-3 py-2 flex justify-between items-center text-sm w-full text-gray-700 hover:bg-WALIGHTBLUE-50"
            >
              {{ $t("edit") }}
            </button>

            <button
              @click.prevent="openConfirmModal(document._id)"
              class="px-3 py-2 flex justify-between items-center w-full text-red-700 text-sm hover:bg-red-500 hover:text-white"
            >
              {{ $t("delete") }}
              <!-- <img src="/copy.svg" alt="" /> -->
            </button>
          </option-dropdown-new>

          <div
            class="absolute -bottom-16 w-80"
            v-if="showConfirm.status && showConfirm.id === document._id"
          >
            <confirm-modal
              :show="showConfirm.status && showConfirm.id === document._id"
              @no="closeConfirmModal"
              @yes="confirmDelete(document._id)"
            ></confirm-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import StatusModal from "../StatusModal.vue";
import ConfirmModal from "../ConfirmModal.vue";
import OptionDropdownNew from "../shared/option-dropdown/OptionDropdownNew.vue";

export default {
  computed: {
    getUrl() {
      return `${process.env.VUE_APP_API_URL}${this.document.link}`;
    },

    ...mapGetters(["currentUser", "accessToken"]),
    isAdminOrEdit() {
      return (
        this.currentUser &&
        (this.currentUser?.role?.document?.admin ||
          this.currentUser?.role?.document?.edit ||
          this.currentUser?.isSuper)
      );
    },

    isAdmin() {
      return (
        this.currentUser &&
        (this.currentUser?.role?.document?.admin || !!this.currentUser?.isSuper)
      );
    },
  },

  components: { StatusModal, ConfirmModal, OptionDropdownNew },
  data() {
    return {
      statusData: [
        { id: 1, value: "Active" },
        { id: 2, value: "Disabled" },
      ],
      status: "",
      showStatus: { status: false, id: "" },
      showOptionDropdown: { status: false, id: "" },
      showConfirm: { status: false, id: "" },
    };
  },

  methods: {
    ...mapActions("documents", ["changeStatus", "deleteDocument"]),

    fileApi() {
      return axios.get(this.getUrl, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
      });
    },

    async downloadItem() {
      const response = await this.fileApi();
      const blob = new Blob([response.data], {
        type: "application/pdf",
      });

      const ext = this.document.link.substr(
        this.document.link.lastIndexOf(".") + 1
      );
      const link = document.createElement("a");

      link.href = URL.createObjectURL(blob);
      link.download = `${this.document.originalUrl}.${ext}`;
      link.click();
      URL.revokeObjectURL(link.href);
    },

    async previewDocument() {
      const response = await this.fileApi();
      const blob = new Blob([response.data], {
        type: "application/pdf",
      });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(
          blob,
          `${this.document.originalUrl}.pdf`
        );
        return;
      }

      const url = window.URL.createObjectURL(blob);
      window.open(url, "_blank");

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(url);
      }, 100);
    },

    formatDate(date) {
      const d = new Date(date);
      const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
      const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
      const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

      return `${day}.${mo}.${ye}`;
    },

    openStatusModal(document) {
      this.showStatus.id = document._id;
      this.showStatus.status = true;
    },

    closeStatusModal(e) {
      if (!e) {
        this.showStatus.status = false;
        this.showStatus.id = "";
      } else if (!this.$el.contains(e.target)) {
        this.showStatus.status = false;
        this.showStatus.id = "";
      }
    },

    openOptionDropDown(id) {
      if (this.showStatus.status) {
        this.showStatus.status = false;
        this.showStatus.id = "";
      }

      this.showOptionDropdown.status = true;
      this.showOptionDropdown.id = id;
    },

    closeOptionDropdown(e) {
      if (!e) {
        this.showOptionDropdown.status = false;
        this.showOptionDropdown.id = "";
      } else if (!this.$el.contains(e.target)) {
        this.showOptionDropdown.status = false;
        this.showOptionDropdown.id = "";
      }
    },

    confirmDelete(id) {
      this.closeConfirmModal();
      this.deleteDocument(id);
    },

    openConfirmModal(id) {
      this.showConfirm.status = true;
      this.showConfirm.id = id;
    },

    closeConfirmModal(e) {
      if (!e) {
        this.showConfirm.status = false;
        this.showConfirm.id = "";
      } else if (!this.$el.contains(e.target)) {
        this.showConfirm.status = false;
        this.showConfirm.id = "";
      }
    },
  },
  props: ["document"],

  mounted() {
    document.addEventListener("click", this.closeStatusModal);
  },

  beforeUnmount() {
    document.removeEventListener("click", this.closeStatusModal);
  },

  watch: {
    async status(status) {
      await this.changeStatus({ status, id: this.document._id });
    },
  },
};
</script>
