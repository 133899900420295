<template>
  <Loader v-if="groupLoading || doctypeLoading" />
  <form v-else @submit.prevent="onSubmit">
    <div v-if="error" class="py-3 px-5 mb-3 rounded bg-red-50 text-red-400">
      <span>* {{ error }}</span>
    </div>
    <div class="mb-4 space-y-3 sm:space-y-0 sm:space-x-3 px-1">
      <select-input
        :placeholder="$t('group')"
        :data="getGroupData('name')"
        v-model="group"
        size="full"
        :filterMode="true"
        :returnId="true"
        :isObject="true"
      />
    </div>
    <div class="mb-4 space-y-3 sm:space-y-0 sm:space-x-3 px-1">
      <select-input
        :placeholder="subgroupLoading ? 'Loading' : $t('sub-group')"
        :data="getSubGroupData('name')"
        v-model="subgroup"
        size="full"
        :disabled="subgroupLoading"
        :filterMode="true"
        :returnId="true"
        :isObject="true"
        ref="subgroup"
      />
    </div>

    <div class="mb-4 space-y-3 sm:space-y-0 sm:space-x-3 px-1">
      <select-input
        :placeholder="sub_subgroupLoading ? 'Loading' : $t('sub-sub-group')"
        :data="getSubSubGroupData('name')"
        v-model="sub_subgroup"
        :disabled="sub_subgroupLoading"
        size="full"
        :filterMode="true"
        :returnId="true"
        :isObject="true"
        ref="subsubgroup"
      />
    </div>

    <div class="mb-4 space-y-3 sm:space-y-0 sm:space-x-3 px-1">
      <select-input
        :placeholder="$t('document-type')"
        :data="getDocTypesData('name')"
        v-model="doctype"
        :disabled="doctypeLoading"
        size="full"
        :filterMode="true"
        :returnId="true"
        :isObject="true"
      />
    </div>

    <div class="mb-4 space-y-3 sm:space-y-0 sm:space-x-3 px-1">
      <input
        type="text"
        :placeholder="$t('document-name')"
        class="rounded w-full"
        v-model="name"
      />
    </div>

    <div
      class="flex flex-col sm:flex-row items-center justify-between mb-4 space-y-3 sm:space-y-0 sm:space-x-3 px-1"
    >
      <div>
        <label
          for="upload"
          class="block bg-yellow-600 py-1.5 px-10 rounded text-white cursor-pointer"
        >
          <img src="/upload-icon.svg" alt="upload" />
        </label>
        <input
          id="upload"
          type="file"
          class="hidden rounded w-full"
          @change="onFileChange"
        />
      </div>

      <p>{{ selectedFile?.name || document?.originalUrl }}</p>
    </div>

    <div class="flex relative items-center justify-end pt-2 gap-x-3 px-1">
      <button
        class="rounded text-white py-1.5 px-4 bg-WAORANGE-500 text-sm"
        :disabled="loading"
      >
        {{
          document
            ? loading
              ? $t("editing")
              : $t("edit")
            : loading
            ? $t("adding")
            : $t("add-document")
        }}
      </button>
    </div>
  </form>
</template>

<script>
import httpClient from "../../services/httpClient";
import { mapActions, mapGetters, mapMutations } from "vuex";

import SelectInput from "@/components/forms/SelectInput.vue";
import Loader from "@/components/Loader.vue";

export default {
  computed: {
    ...mapGetters("groups", { groupList: "list" }),
    ...mapGetters("groups", { groupLoading: "loading" }),
    ...mapGetters("doctypes", { doctypeLoading: "loading" }),
    ...mapGetters("doctypes", { doctypeList: "list" }),
    ...mapGetters("subgroups", { subgroupList: "list" }),
    ...mapGetters("subgroups", { subgroupLoading: "loading" }),
    ...mapGetters("sub_subgroups", { sub_subgroupList: "list" }),
    ...mapGetters("sub_subgroups", { sub_subgroupLoading: "loading" }),
  },

  components: { SelectInput, Loader },

  async created() {
    await this.getGroups();
    await this.getDocTypes();
    this.clearSubGroup();
    this.clearSubSubGroup();

    if (this.document) {
      if (this.document.group)
        await this.getSubGroups(this.document.group?._id);

      if (this.document.subgroup)
        await this.getSubSubGroups(this.document.subgroup?._id);
    }
  },

  data() {
    return {
      loading: false,
      error: null,
      selectedGroup: this.document?.group || null,
      selectedSubGroup: this.document?.subgroup || null,
      selectedFile: null,
      name: this.document?.name || "",

      group: {
        id: this.document?.group?._id || "",
        name: this.document?.group?.name || "",
      },

      subgroup: {
        id: this.document?.subgroup?._id || "",
        name: this.document?.subgroup?.name || "",
      },

      sub_subgroup: {
        id: this.document?.sub_subgroup?._id || "",
        name: this.document?.sub_subgroup?.name || "",
      },

      doctype: {
        id: this.document?.doctype?._id || "",
        name: this.document?.doctype?.name || "",
      },

      statusData: [
        { id: 1, value: "Under Review" },
        { id: 2, value: "Submitted" },
      ],
    };
  },

  emits: ["onClose"],

  methods: {
    ...mapMutations("documents", [
      "addDocument",
      "updateDocument",
      "removeDocument",
    ]),
    ...mapMutations("subgroups", ["clearSubGroup"]),
    ...mapMutations("sub_subgroups", ["clearSubSubGroup"]),
    ...mapActions("groups", ["getGroups"]),
    ...mapActions("doctypes", ["getDocTypes"]),
    ...mapActions("subgroups", ["getSubGroups"]),
    ...mapActions("sub_subgroups", ["getSubSubGroups"]),

    onFileChange(e) {
      this.selectedFile = e.target.files[0];
    },

    async onSubmit() {
      if (this.error) this.error = null;
      if (!this.group?.id && !this.subgroup?.id && !this.sub_subgroup.id)
        return (this.error =
          "Please choose group or sub group or sub-subgroup");
      if (!this.doctype.id) return (this.error = "Document type is required");

      // form data
      const fd = new FormData();
      try {
        this.loading = true;

        if (this.document) {
          fd.append("name", this.name);
          fd.append("group", this.group.id);
          fd.append("subgroup", this.subgroup.id);
          fd.append("sub_subgroup", this.sub_subgroup.id);
          fd.append("doctype", this.doctype.id);
          if (this.selectedFile) fd.append("file", this.selectedFile);

          const { data } = await httpClient.put(
            `/documents/${this.document._id}`,
            fd,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          this.updateDocument(data);
        } else {
          const fd = new FormData();
          fd.append("name", this.name);
          fd.append("group", this.group.id);
          fd.append("subgroup", this.subgroup.id);
          fd.append("sub_subgroup", this.sub_subgroup.id);
          fd.append("doctype", this.doctype.id);
          fd.append("file", this.selectedFile);

          const { data } = await httpClient.post(`/documents`, fd, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          this.addDocument(data);
        }

        this.loading = false;
        this.$emit("onClose");
      } catch (err) {
        this.loading = false;
        this.error =
          err.response && err.response.data.error
            ? err.response.data.error
            : err.error || err.message;
      }
    },

    getGroupData(name) {
      return this.groupList.map((group) => ({
        id: group._id,
        value: group[name],
      }));
    },

    getSubGroupData(name) {
      return this.subgroupList.map((subg) => ({
        id: subg._id,
        value: subg[name],
      }));
    },

    getSubSubGroupData(name) {
      return this.sub_subgroupList.map((group) => ({
        id: group._id,
        value: group[name],
      }));
    },

    getDocTypesData(name) {
      return this.doctypeList.map((doctype) => ({
        id: doctype._id,
        value: doctype[name],
      }));
    },

    clearSubGroups() {},
  },
  props: ["document"],

  watch: {
    async group(newValue) {
      this.clearSubGroup();
      this.clearSubSubGroup();
      this.$refs.subgroup.resetData();
      this.$refs.subsubgroup.resetData();

      const found = this.groupList.find((group) => group._id === newValue.id);

      if (found) {
        this.selectedGroup = found;

        if (newValue.id !== "") await this.getSubGroups(newValue.id);
      } else {
        this.selectedGroup = null;
      }
    },

    async subgroup(newValue) {
      this.clearSubSubGroup();
      this.$refs.subsubgroup.resetData();

      const found = this.subgroupList.find(
        (subgroup) => subgroup._id === newValue.id
      );

      if (found) {
        this.selectedSubGroup = found;

        await this.getSubSubGroups(newValue.id);
      } else this.selectedSubGroup = null;
    },
  },
};
</script>

<style scoped>
input,
textarea {
  border: none;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

form {
  width: 80vw;
  max-height: 100vh;
}

@media screen and (min-width: 640px) {
  form {
    width: 500px;
  }
}
</style>
