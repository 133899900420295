<template>
  <div class="max-w-screen-2xl mx-auto px-4 py-2 lg:px-4">
    <div class="flex items-center justify-between">
      <div class="flex flex-row">
        <sort-label
          name="name"
          :placeholder="$t('name')"
          classes="pr-1 flex w-44 md:w-80"
          :sortData="sort"
          @sort="onSort"
        ></sort-label>

        <div class="hidden px-1 w-16 sm:flex">
          <p class="font-semibold text-sm">{{ $t("links") }}</p>
        </div>

        <sort-label
          name="doctype"
          :placeholder="$t('document-type')"
          classes="hidden px-1 w-40 sm:flex"
          :sortData="sort"
          @sort="onSort"
        ></sort-label>

        <sort-label
          name="createdAt"
          :placeholder="$t('added')"
          classes="hidden px-1 w-28 sm:flex"
          :sortData="sort"
          @sort="onSort"
        ></sort-label>

        <sort-label
          name="group"
          :placeholder="$t('group')"
          classes="hidden px-1 w-36 lg:flex"
          :sortData="sort"
          @sort="onSort"
        ></sort-label>

        <sort-label
          name="subgroup"
          :placeholder="$t('sub-group')"
          classes="hidden px-1 w-44 lg:flex"
          :sortData="sort"
          @sort="onSort"
        ></sort-label>

        <sort-label
          name="sub_subgroup"
          :placeholder="$t('sub-sub-group')"
          classes="hidden px-1 md:flex  w-36 "
          :sortData="sort"
          @sort="onSort"
        ></sort-label>
      </div>
      <div class="flex flex-row">
        <sort-label
          name="status"
          :placeholder="$t('status')"
          classes="flex justify-end sm:flex w-20"
          :sortData="sort"
          @sort="onSort"
        ></sort-label>
        <div v-if="isAdminOrEdit" class="flex justify-end sm:flex w-20">
          <p class="font-semibold text-sm">{{ $t("action") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SortLabel from "@/components/SortLabel";
import { mapGetters, mapMutations } from "vuex";

export default {
  components: { SortLabel },
  computed: {
    ...mapGetters("documents", ["sort"]),
    ...mapGetters(["currentUser"]),
    isAdminOrEdit() {
      return (
        this.currentUser &&
        (this.currentUser?.role?.document?.admin ||
          this.currentUser?.role?.document?.edit ||
          this.currentUser?.isSuper)
      );
    },
  },

  methods: {
    ...mapMutations("documents", ["sortDocument", "toggleSort"]),
    onSort(by) {
      this.toggleSort();
      this.sortDocument(by);
    },
  },
};
</script>
