<template>
  <Modal :show="showModal" @onClose="closeModal">
    <template #header>{{
      selectedDocument ? $t("edit") : $t("add-document")
    }}</template>
    <template #body
      ><document-form @onClose="closeModal" :document="selectedDocument"
    /></template>
  </Modal>

  <Loader v-if="loading && list.length === 0" />
  <div v-else>
    <section class="max-w-screen-2xl mx-auto pt-6 px-2 sm:px-6 lg:px-8">
      <div class="flex flex-row items-center justify-between">
        <div class="flex items-center">
          <div class="mr-2">
            <img src="/icon-title-documents.svg" class="h-5 w-5" />
          </div>
          <div>
            <h1 class="font-bold text-WADARKBLUE-500 text-base uppercase">
              {{ $t("documents") }}
            </h1>
          </div>
        </div>
      </div>
    </section>
    <section class="max-w-screen-2xl mx-auto px-2 py-6 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between">
        <div class="flex items-center" v>
          <button
            @click="openModal"
            v-if="isAdminOrEdit"
            class="
              bg-WAORANGE-500
              hover:bg-WAORANGE-400
              mr-2
              px-4
              py-2
              rounded-lg
              uppercase
              text-white text-xs
            "
          >
            + {{ $t("add-document") }}
          </button>
          <router-link
            v-if="isAdmin"
            class="
              text-xs
              py-2
              px-4
              border border-WAORANGE-500
              hover:bg-white
              rounded-lg
              uppercase
            "
            to="/doctypes"
            >{{ $t("document-types") }}</router-link
          >
        </div>
        <document-filter />
      </div>
    </section>
    <section class="lg:px-8 max-w-screen-2xl mx-auto px-2 sm:px-6">
      <document-table-row />
      <div v-if="error" class="py-3 px-5 mb-3 rounded bg-red-50 text-red-400">
        <span>* {{ error }}</span>
      </div>
      <div v-if="filteredDocuments.length > 0">
        <Document
          v-for="document in filteredDocuments"
          :key="document._id"
          :document="document"
          @edit="selectDocument(document)"
        />
      </div>
      <div v-else>
        <h1 class="text-sm text-center text-WADARKBLUE-500 my-10">
          {{ $t("no-data") }}
        </h1>
      </div>
    </section>
  </div>
</template>

<script>
import Document from "@/components/documents/Document.vue";
import DocumentTableRow from "@/components/documents/DocumentTableRow.vue";
import DocumentFilter from "@/components/documents/DocumentFilter.vue";
import DocumentForm from "@/components/documents/DocumentForm.vue";
import Loader from "@/components/Loader.vue";
import Modal from "@/components/Modal";

import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return { showModal: false, selectedDocument: null };
  },
  computed: {
    ...mapGetters("documents", [
      "list",
      "loading",
      "error",
      "filteredDocuments",
    ]),
    ...mapGetters(["currentUser"]),
    isAdminOrEdit() {
      return (
        this.currentUser &&
        (this.currentUser?.role?.document?.admin ||
          this.currentUser?.role?.document?.edit ||
          this.currentUser?.isSuper)
      );
    },
    isAdmin() {
      return (
        this.currentUser &&
        (this.currentUser?.role?.document.admin || this.currentUser?.isSuper)
      );
    },
    dataLoaded: () => !this.loading && this.list.length > 0,
  },
  components: {
    Document,
    Modal,
    DocumentForm,
    DocumentTableRow,
    DocumentFilter,
    Loader,
  },
  methods: {
    ...mapActions("documents", ["getDocuments", "error"]),

    openModal() {
      this.selectedDocument = null;
      this.showModal = true;
    },

    closeModal() {
      this.showModal = false;
      if (this.selectedDocument) this.selectedDocument = null;
    },

    selectDocument(document) {
      this.showModal = true;
      this.selectedDocument = document;
    },
  },

  async created() {
    await this.getDocuments();
  },
};
</script>
